.allContentLayout{
    width:80%;
     margin:2rem auto 4rem;
}

@media (max-width: 500px){
    .allContentLayout{
        width:90%;
         margin:2rem auto 4rem;
    }
}