.ql-size {
  margin-bottom: 12px;
}
/* .ql-size .ql-picker-label {
    background-color: red;
    align-items: center;
} */

.reactquill {
  height: 400px;
}
.ql-container.ql-snow {
  height: 100% !important;
}
.ql-editor.ql-blank {
  height: 100% !important;
}

span.ql-picker-label svg {
  margin-bottom: 20px;
}
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    position: absolute;
    /* margin-top: -9px; */
    right: 0;
    /* top: 40% !important; */
    width: 18px;
}
 @media (max-width: 1200px){
    .ql-size {
        margin-bottom: 5px;
      }
    }
    .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
        position: absolute;
        /* margin-top: -9px; */
        right: 0;
        /* top: 30% !important; */
        width: 18px;
    }
/*
    @media (max-width: 918px){
      .ql-container.ql-snow{
        height: 80% !important;
      
      }
     
      .boarderInsertTable td:first-child{
        background-color: #e9e9e9;
        width: 60px;
        font-weight: bold;
        padding-left: 1em;
        margin: 1rem;
    
      }
      .ql-toolbar .ql-snow{
       font-size: 10px;
      }
    }
    @media (max-width: 564px){
      .ql-container.ql-snow{
        height: 72% !important;
      
      }
     
      .boarderInsertTable td:first-child{
        background-color: #e9e9e9;
        width: 60px;
        font-weight: bold;
        padding-left: 1em;
        margin: 1rem;
    
      }
      .ql-toolbar .ql-snow{
       font-size: 10px;
      }
    } */
