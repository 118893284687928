.bar{
    padding: 12px;
    width: 100%;
    background-color: black;
  }
  
.bar a span{
      color: white;
      padding: 0 4rem;
      font-weight: bold;
      font-size: 16px;
}
  
.bar a span:hover{
    color: #c6c3c3;
    cursor: pointer;
}

.contentSubtitle{
  width:80%;
  margin: 0 auto;
  margin-top: 4rem;
}

.contentboard{
  font-size: 1.9rem;
  font-weight: bold;
  padding-bottom: 1rem;
  border-bottom: 2px solid grey;
  margin-bottom: 2rem;
}

@media (max-width: 500px){
  html{
    font-size: 80%;
  }

  .bar a span{
    color: white;
    padding: 0 2rem;
    font-weight: bold;
    font-size: 1.2rem;
}
.bar a span:hover{
  color: #c6c3c3;
  cursor: pointer;
}
.contentSubtitle{
  width:85%;
  margin: 0 auto;
  margin-top: 4rem;
}
}

@media (max-width: 300px){
  html{
    font-size: 60%;
  }

  .bar a span{
    color: white;
    padding: 0 2rem;
    font-weight: bold;
    font-size: 1.2rem;
}
.bar a span:hover{
  color: #c6c3c3;
  cursor: pointer;
}
}