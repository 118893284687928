.registerconfirm-container{
    padding: 4rem 0;
    width: 80%;
    margin: 0 auto;
    text-align: left;
}
.contract{
    padding:1rem;
}
.privacyStatement{
  height: 15rem; 
  border: 1px solid #c1c1c1;  
  background-color: white;
  white-space: pre-line; 
  overflow-y: scroll;   
  text-align: left;  
  padding: 1.8rem;
  width: 100%;
  line-height: 1.5rem;
}

.contract-title{
 font-size: 1.2rem;
 padding: 1rem 0;
}

.agreebox{
  text-align: right;
  margin: 1rem 0;
}

.registerconfirm-Btn{
  text-align: center;
  margin-top: 4rem;
}

.registerconfirm-Btn button{
    padding:0.5rem 2.8rem;
    font-size: 1.2rem;
    border: none;
    background: rgb(16, 56,172);
    color: white;
    border-radius: 0.5rem;
}