.board-wrap{
  width: 95%;
  margin: 0 auto;
  min-height: 200px;
}

.boardwrite{
  text-align: right;
  margin-bottom: 0.8rem;
}
.board {
  width: 100%;
  margin: 0 auto;
  line-height: 2.5rem;
  margin-bottom: 1.2rem;
  border-bottom: 1px solid #c1c1c1;
  text-align: center;
}
.board thead{ 
  color: black;
  height: 3rem;
  background-color:#e9e9e9;
  border-top: 1px solid black;
}
.board tbody tr{ 
  border-bottom: 1px solid #c1c1c1;
  color: black;
}
.searchSBox {
  vertical-align: bottom;
}

.board tbody td:first-child{
  width: 10%;
}

.board tbody td:nth-child(3){
  width: 10%;
}
.board tbody td:nth-child(4){
  width: 10%;
}

.board tbody td:last-child{
  width: 10%;
}

.searchSBox select {
  margin-right: 0.3rem;
  height: 2.1rem;
}

.searchSBox input {
  margin-right: 0.3rem;
  border: 1px solid #c1c1c1;
  height: 2.2rem;
}
.searchSBox button {
  border: 0;
  background-color: rgb(16, 56,172);
  padding: 0.37rem 1rem;
  border-radius: 2px;
  color: white;
  text-align: center;
  vertical-align: bottom;
  cursor: pointer;
}
.bottompagination{
  display: flex;
  justify-content: center;}

  .active>.page-link, .page-link.active{
    background-color: rgb(16, 56,172) !important;
   border-color:  rgb(16, 56,172) !important;
  }


  @media(max-width : 768px){
    .board tbody td:first-child{
      width: 10%;
    }
    .board tbody td:nth-child(2){
      width: 40%;
      font-size: 13px;
      text-align: center;
    }
    
    .board tbody td:nth-child(4){
      width: 10%;
    }
    .board tbody td:last-child{
      width: 10%;
    }
  }
  @media(max-width : 340px){
    .board-wrap{
      width: 100%;
      margin: 0 auto;
    }

    
    .searchBox input {
      width: 85px;
      margin-right: 0.3rem;
      border: 1px solid #c1c1c1;
      height: 2.2rem;
    }

  }

  @media(max-width : 230px){

    
    .searchSBox input {
      width: 50px;
      margin-right: 0.3rem;
      border: 1px solid #c1c1c1;
      height: 2.2rem;
    }

  }