.signIn-box {
  padding: 4rem 0;
}

.signIn-container{
  padding: 20px 50px;
  border-radius: 5px;
  width: 470px;
  margin: 0 auto;  
  font-size:1rem
}

.signIn-box label {
  font-weight: bold;
  font-size: 14px;
}

.registerBtn {
  width: 100%;
  background-color: rgb(16, 56,172);
  color: white;
  padding: 12px 16px;
  font-size: 18px;
  border: 1px solid rgb(16, 56,172);
  border-radius: 3px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 15px;
}

.signInWrap input {
  outline: 0;
  border: 1px solid #b3b2b2;
  margin-top: 7px;
  margin-bottom: 8px;
  border-radius: 4px;
  width: 100%;
  padding: 9px 8px;
  font-size: 18px;
}

.signHead {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 25px;
  font-weight: bold;
}
::placeholder {
  color: grey;
  font-size: 13px;
}
.register-id-eplain{
  text-align: left;
  color:grey;
  font-size: 12px;
}

.emailAgreebox-wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.emailAgreeChekbox{
  display: flex;
  align-items: center;
}

.emailAgreeChekbox input[type="checkbox"]{
  width: 1rem;
  margin-right: 0.5rem;
  margin-left:0.5rem;
  position: relative;
}

@media (max-width: 500px){
  .signIn-container{
    padding: 15px 30px;
    border-radius: 5px;
    width: 100%;
    margin: 0 auto;  
    font-size:1rem
  }
}