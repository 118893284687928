.nav-container {
  height: 4.5rem;
  margin: 2rem auto;
}

.nav-wrap{
  width: 85%;
  margin: 0 auto;
}


.nav-loginlist {
  text-align: right;
}
.nav-loginlist a {
  margin-right: 0.6rem;
  font-weight: bold;
  background-color: rgb(16, 56,172);
  color: #f1f1f1;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
  cursor: pointer;
}
.nav-loginlist span {
  margin-right: 0.6rem;
  font-weight: bold;
  background-color: rgb(16, 56,172);
  color: #f1f1f1;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
  cursor: pointer;
}

.nav{
  display:flex;
  justify-content: space-between;
  /* background-color: rgb(238, 255, 0); */
}
.nav-left img{
  width: 13rem;
  margin-top: 0.5rem;
}
.nav-right ul{
  display: flex;
  margin-top: 0.6rem;
}

.nav-right ul li{
  margin: 1.3rem 3rem 1.3rem 3rem;
  font-weight: bold;
  font-size: 1.4rem;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 9.8rem;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  z-index: 10;
  padding-top: 1rem;
}

.dropdown-content a {
  color: black;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 1rem;

}

.dropdown-content a:hover {background-color: #f1f1f1;}

.dropdown:hover .dropdown-content {
  display: block;
}

.fabar{
  width: 2.2rem;
  display: inline-block;
  position: absolute;
  top:2.3rem;
  right: 3rem;
}
.faX{
  color: white;
}
.login-span{
  font-size: 18px;
}

@media (max-width: 1200px) {
  .nav-container {
    height: 4rem;
    margin: 1.5rem auto;
  }
  .nav-left img{
    margin-top: 0;
  }
  .navHamberMenu{
    width: 100%;
  }
 .hamberMenu{
  display: flex;
  justify-content: space-between;
  background: rgba(0, 0, 0,0.8);
  width: 22rem;
  height: 100%;
  position: fixed;
  z-index: 5;
  padding: 4rem 3rem;
  text-align: left;
  transition: all 1s;
  transform: translate(-160px, -18px);
 }
  

 .hamberMenu ul{
  height: auto;
  color: white;
  padding-left: 0;
 }
  .hamberMenu ul li a{
    font-size: 18px;
    color: white;
    z-index: 5; 
  }  
  .hamberMenu ul li a:hover{
    cursor: pointer;
    color: #c6c3c3;
  }  
  .accordion{
    --bs-accordion-btn-active-icon:url("../image/down.svg") !important;
  }
  .accordion .headitem{
    border: 0;
    border-radius: 0;
    background-color: transparent;

  }
  
  .accordion .headitem .accordion-header button{
    background-color: transparent;
    box-shadow: none;
    color: white;
  }
  .accordion .headitem .accordion-button.collapsed {
    color: white;
    padding: 5px 0;
   }
  
  .accordion .headitem  .accordion-button{
    color: white;
    padding: 5px 0;
  }
  /* .accordion .item .accordion-header button::after{
   transition: all .6s ease;
  } */
  .accordion-header{
    color: white;
    width: 100%;
  }
  .accodionBody{
    border:none;
    padding: 0 0 0 10px;
  }
  .accodionBody a{
    font-size: 6px;
  }
  .accodionBody a:hover{
    cursor: pointer;
    color: #c6c3c3;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("../image/up.svg");
  }
  .accordion-button.collapsed::after {
    background-image: url("../image/down.svg");
    /* background-image: url("../image/up.svg"); */
   
  }
  .accordion-button:after{
    background-image: url("../image/up.svg");
  }
  .headitem .accordion-item a:hover{
     cursor: pointer;
     color:#c6c3c3;
  }

}

@media(max-width:1040px){
  .hamberMenu{
    display: flex;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.8);
    width: 20rem;
    height: 100%;
    position: fixed;
    z-index: 5;
    padding: 3rem;
    text-align: left;
    transition: all 1s;
    transform: translate(-145px, -20px);
   }
  }
@media(max-width:900px){
  .hamberMenu{
    display: flex;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.8);
    width: 18.8rem;
    height: 100%;
    position: fixed;
    z-index: 5;
    padding: 3rem;
    text-align: left;
    transition: all 1s;
    transform: translate(-173px, -20px);
   }

   .hamberMenu ul{
    height: auto;
    color: white;
   }
    .hamberMenu ul li a{
      font-size: 16px;
      color: white;
      z-index: 5; 
    }  
    .accordion .headitem{
      border: 0;
      border-radius: 0;
      background-color: transparent;
    }
    
    .accordion .headitem .accordion-header button{
      background-color: transparent;
      box-shadow: none;
      color: white;
    }
    .accordion .headitem .accordion-button.collapsed {
      color: white;
      padding: 5px 0;
     }
    
    .accordion .headitem  .accordion-button{
      color: white;
      padding: 5px 0;
    }
    /* .accordion .item .accordion-header button::after{
     transition: all .6s ease;
    } */
    .accordion-header{
      color: white;
      width: 100%;
    }
    .accodionBody{
      border:none;
      padding: 0 0 0 10px;
    }
    .accodionBody a{
      font-size: 6px;
    }
    .accordion-button:not(.collapsed)::after {
      background-image: url("../image/up.svg");
    }
    .accordion-button.collapsed::after {
      background-image: url("../image/down.svg");
     
    }
    .accordion-button:after{
      background-image: url("../image/up.svg");
    }
    .login-span{
      font-size: 16px;
    }

}

@media (max-width: 760px){
  .hamberMenu{
    height: 100%;
    transform: translate(-185px, -20px);
  }
  
  .hamberMenu ul{
    height: 100%;
    color: white;
  }
  .hamberMenu ul li a{
    font-size: 15px;
    color: white;
    z-index: 5; 
  }  
}
@media (max-width: 560px){ 
 .nav-container {
  height: 2rem;
  margin: 1.4rem auto;
 }
 .nav-left img{
    width: 9rem;
    position: absolute;
    top:0.9rem;
 }
  .fabar{
    width: 1.7rem;
    display: inline-block;
    position: absolute;
    top:1.1rem;
    right: 2.8rem;
  }
  .hamberMenu{
    display: flex;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.8);
    width: 17rem;
    height: 100%;
    position: fixed;
    z-index: 5;
    padding: 3rem;
    text-align: left;
    transition: all 1s;
    transform: translate(-176px, -18px);
  }
  
  .hamberMenu ul{
    height: auto;
    color: white;
  }
  .hamberMenu ul li a{
    font-size: 15px;
    color: white;
    z-index: 5; 
  }  

}
  @media (max-width: 450px){
  .hamberMenu{
    display: flex;
    justify-content: space-between;
    background:rgba(0, 0, 0, 0.8);
    width: 18rem;
    height: 100%;
    position: fixed;
    z-index: 5;
    padding: 3rem;
    text-align: left;
    transition: all 1s;
    transform: translate(-195px, -18px);
  }
  
  .hamberMenu ul{
    height: 100%;
    color: white;
  }
  .hamberMenu ul li a{
    font-size: 15px;
    color: white;
    z-index: 5; 
  }  
}

@media (max-width: 380px){
  .hamberMenu{
    display: flex;
    justify-content: space-between;
    background:rgba(0, 0, 0, 0.8);
    width: 16rem;
    height: 100%;
    position: fixed;
    z-index: 5;
    padding: 3rem;
    text-align: left;
    transition: all 1s;
    transform: translate(-172px, -18px);
  }
  
  .hamberMenu ul{
    height: 100%;
    color: white;
  }
  .hamberMenu ul li a{
    font-size: 15px;
    color: white;
    z-index: 5; 
  }  

}
@media (max-width: 380px){
  .hamberMenu{
    display: flex;
    justify-content: space-between;
    background:(0, 0, 0, 0.8);
    width: 18rem;
    height: 100%;
    position: fixed;
    z-index: 5;
    padding: 4rem 3rem 0 2rem;
    text-align: left;
    transition: all 1s;
    transform: translate(-195px, -18px);
  }
}
@media (max-width: 300px){
  .hamberMenu{
    display: flex;
    justify-content: space-between;
    background:rgba(0, 0, 0, 0.8);
    width: 20rem;
    height: 100%;
    position: fixed;
    z-index: 5;
    padding: 3.5rem 2.5rem;
    text-align: left;
    transition: all 1s;
    transform: translate(-168px, -18px);
  }
  
  .hamberMenu ul li a{
    font-size: 15px;
    color: white;
    z-index: 5; 
  }  

  .accordion-header{
    color: white;
    width: 100%;
  }
  
}