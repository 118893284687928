@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+KR&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300&display=swap");

* {
  margin: 0;
  padding: 0;
  border-collapse: collapse;
}

body {
  font-family: "Noto Serif KR", serif;
}

a {
  text-decoration: none;
  color: black;
}

li {
  list-style: none;
}