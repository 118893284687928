.main-container{
  width: 100vw;
  text-align: center;

}
/*slider section*/
.test-css {
  bottom: 1.2rem;
  width: 100vw;
  list-style: none;
  position: absolute;
  right: 15px;
  padding: 0;
}

.test-css li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
}

.test-css li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}

.test-css li button:before {
  font-size: 2.7rem;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .75;
  color: #dbdbdb;
}

.test-css li.slick-active button:before {
  opacity: .75;
  color: #ffffff;
}

.slide .brandTitle1{
  transform: translate(80px,440px);
  width: 40rem;
  /* animation: left 1s 0.4s both; */
  /* animation-iteration-count: infinite */
}


.slide .brandTitle2{
  /* margin-top: 1rem; */
  width: 32rem;
  transform: translate(104px, 445px);
 /* animation: left2 1s 0.9s both ;쇼핑할땐 보다 0.2s느리게 */
}

/*brand section*/
.brand{
  margin: 7rem 0;
}

.brand-container{
  width:70%;
  margin: 0 auto;

}

.brand-title{
  text-align:center;
}
.brand-title-img{
  width: 42rem;
  margin-bottom: 1.1rem;
}
.brand-content-img{
  width: 25rem;
}
.brand-title p{
  font-size: 1.2rem;
  line-height: 0.7rem;
  font-weight: bold;
}

.brand-content{
  align-items: center;
}
.card-img{
  max-width: 260px;
  padding: 1.7rem;
  box-shadow: 0 3px 4px rgba(0,0,0,0.07), 
  0 2px 4px rgba(0,0,0,0.07), 
  0 2px 3px rgba(0,0,0,0.07), 
  0 2px 4px rgba(0,0,0,0.07),
  0 2px 4px rgba(0,0,0,0.07), 
  0 3px 6px rgba(0,0,0,0.07);
  /* background-color:  #FFCA18; */
  background:  linear-gradient(to top, #FFCA18, transparent); 
}

.card-img:hover {
  transform: scale( 1.02 )
}


/*notice section*/
.notice-container{
  background-color: #e9e9e9;
  padding: 6rem 0;
}

.nav-tabs .nav-link{
  border-bottom: none;
  color: rgb(16, 56,172);
  font-size: 1.3rem;
  font-weight: bold;
  padding: 0.8rem;
}

.nav-tabs .nav-link:hover{
  color: grey;
}

.tab-content{
  background-color: white;
  height: 26rem;
  border-bottom-left-radius:0.5rem;
  border-bottom-right-radius:0.5rem;

}

/*gallery section*/
.gallery{
  height:27rem;
  margin: 6rem 0 11rem 0;
}

.gallery-container{
  width: 78%;
  margin: 0 auto;
}

.gallery-container h2{
  text-align: left;  
  margin-bottom: 1rem;
  color: rgb(16, 56,172);
  font-weight: bold;
  font-size: 2.3rem;
}
.gallery-container p{
  margin-top: 0.7rem;
}

.gallery-css {
  bottom: -3rem;
  width: 100%;
  list-style: none;
  position: absolute;
  right: 15px;
  padding: 0;
}

.gallery-css li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
}

.gallery-css li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}

.gallery-css li button:before {
  font-size: 2.7rem;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .75;
  color: #c1c1c1;
}

.gallery-css li.slick-active button:before {
  opacity: .75;
  color: rgb(16, 56,172);
}
.gallery-img img{
   width: 94%;
   height: 20rem;
   border-radius: 2rem;
   margin-right: 1rem;
   margin: 0 auto;
}



/* media queries  */
@media (max-width: 1440px) {
  html {
    font-size: 90%;
  }
  .brand-container{
    width: 60%;
  }
  .hamberMenu{
    background-color: black;
  }

  @media (max-width: 1350px) {
  
    .gallery-img img{
      width: 94%;
      height: 16rem;
      border-radius: 2rem;
      margin-right: 1rem;
      margin: 0 auto;
   }
  }

@media (max-width: 1200px) {
  html {
      font-size: 70%;
  }
  .slide .brandTitle1{
      transform: translate(10px,350px);
      width: 38rem;
  }  
    
  .slide .brandTitle2{
      width: 30rem;
      transform: translate(26px, 355px);
  }
  .brand-container{
    width: 60%;
  }


  .brand-content img{
    width: 19rem;
    margin: 1rem;
    padding: 1rem;
  }
 
  .gallery-img img{
    width: 94%;
    height: 20rem;
    border-radius: 2rem;
    margin-right: 1rem;
    margin: 0 auto;
 }
}

@media(max-width: 1000px){
  .brand-container{
    width: 60%;
  }

  
  .brand-content{
    margin: 1rem;
    border: 1px solid #dbdbdb;
  }
  .brand-content img{
    width: 17rem;
    margin: 1rem;
    padding: 1rem;
  }

  .gallery-img img{
    width: 94%;
    height: 20rem;
    border-radius: 2rem;
    margin: 0 auto;
 }

}

@media (max-width: 900px) {
  html {
    font-size: 80%;
  }

  .brand-title-img{
    width: 30rem;
    margin-bottom: 1.1rem;
  }
  .brand-container{
    width: 60%;
   }
 

  .brand-content{
    margin: 0.5rem;
    border: 1px solid #dbdbdb;
    border-radius: 2rem;
    }
  .brand-content img{
    width: 12rem;
  }
  .card-img{
    padding: 1.2rem;
  }
   
  .nav-tabs .nav-link{
    font-size: 0.95rem;
  }

   .gallery-img img{
    width: 94%;
    height: 18rem;
    border-radius: 2rem;
    margin-right: 1rem;
    margin: 0 auto;
 }
 
}

@media (max-width: 800px) {
  .gallery-img img{
    width: 94%;
    height: 16rem;
    border-radius: 2rem;
    margin-right: 1rem;
    margin: 0 auto;
 }
}
@media (max-width: 750px) {
  .gallery-img img{
    width: 14rem;
    height: 16rem;
    border-radius: 2rem;
    margin-right: 1rem;
    margin: 0 auto;
 }
}
@media (max-width: 720px) {
  html {
    font-size: 80%;
  }

  .brand-title-img{
    width: 30rem;
    margin-bottom: 1.1rem;
  }
  .brand-container{
    width: 80%;
   }


  .brand-content{
    margin: 0.5rem;
    border: 1px solid #dbdbdb;
    border-radius: 2rem;
    }
  .brand-content img{
    width: 8rem;
  } 
  .gallery-img img{
    width: 13rem;
    height: 13rem;
    border-radius: 2rem;
    margin-right: 1rem;
 }
}

@media (max-width: 650px) {
  .gallery-img img{
    width: 18rem;
    height: 18rem;
    border-radius: 2rem;
    margin-right: 1rem;
 }
}
@media (max-width: 600px) {
  .gallery-img img{
    width: 16rem;
    height: 16rem;
    border-radius: 2rem;
    margin-right: 1rem;
 }
}
@media (max-width: 560px) {
  .brand-title-img{
    width: 26rem;
    margin-bottom: 1.1rem;
  }
   .brand-container{
    width: 90%;
   }

   .brand-content-img{
    width: 21rem;
  }
  .card-img{
    padding: 0.8rem;
  }

  /* .brand-content-wrap-moblie{
    display: flex;
     margin: 0.1rem auto; 
    width: 90%;
    margin: 0 auto;
  }

  .brand-content{
    border: 1px solid #dbdbdb;
    border-radius: 1rem;
    margin-top: 1.5rem;
  }
  .brand-content img{
    width: 8rem;
  } */

 .nav-tabs .nav-link{
   font-size: 0.95rem;
 }

 .mobileMainTitle1{
  transform: translate(-15px,160px);
  color: white;
  font-size: 3.3rem;
  font-weight: bold;
}

.mobileMainTitle2{
  transform: translate(-10px,155px);
  color: white;
  font-size: 3rem;
  font-weight: bold;
}
 .mobileMainSubTitle{
  transform: translate(0px,170px);
  color: #dbdbdb;
  font-size: 1.3rem;
}

.gallery-img img{
  width: 94%;
  height: 16rem;
  border-radius: 2rem;
  margin-right: 1rem;
  margin: 0 auto;
}



}

@media (max-width: 500px) {

  .brand-title-img{
    width: 27rem;
    margin-bottom: 1.1rem;
  }
   .brand-container{
    width: 90%;
   }

   .brand-content-img{
    width: 22rem;
  }

 
  .brand-content img{
    width: 7rem;
  }

 .nav-tabs .nav-link{
   font-size: 0.95rem;
 }
 .gallery-img img{
  width: 94%;
  height: 23rem;
  margin: 0 auto;
}

}

@media (max-width: 400px){
  .brand-title-img{
    width: 24rem;
    margin-bottom: 1.1rem;
  }
  .brand-content-img{
    width: 20rem;
  }
  .card-img{
    padding: 0.5rem;
  }

}

@media (max-width: 280px){
  .mobileMainTitle1{
    font-size: 3rem;
    transform: translate(0px,130px);
  }

  .mobileMainTitle2{
    font-size: 3rem;
    margin-bottom: 0;
    transform: translate(0px,130px);
  }

  .mobileMainSubTitle{
    font-size: 1.2rem;
    transform: translate(0px,135px);
  }

  .brand{
    margin: 4rem 0;
  }
  

  .brand-title-img{
    width: 18rem;
    margin-bottom: 1.1rem;
  }
  .brand-content-img{
    width: 16rem;
  }

  .card-img{
    padding: 0.2rem;
  }
  
  .nav-tabs .nav-link{
    border-bottom: none;
    color: #1038ac;
    font-weight: bold;
    font-size: 10px;
  }
  
  .nav-tabs .nav-link:hover{
    color: grey;
  }

  .notice{
    width: 98%;
    margin: 0 0 0 2rem;
  }
  
  .tab-content{
    background-color: white;
    height: 16rem;
  }

  .gallery{
    margin: 3rem 0;
  }

  .gallery-img img{
    width: 20rem;
    height: 16rem;
  }
}

}