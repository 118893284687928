.login-container{
  padding: 4rem 0;
}

.login-box {
  padding: 20px 50px;
  border-radius: 5px;
  max-width: 470px;
  margin: 0 auto;  
  font-size:1rem
}

.login-container h2 {
  margin-bottom:1.6rem;

  font-weight: bold;
}

.login-container p {
  line-height: 0.7rem;
  font-size: 14px;
  color: rgb(116, 116, 116)
}


.login-box label {
  font-weight: bold;
  font-size: 14px;
}

.login-wrap .loginBtn {
  border: 0;
  background-color: rgb(16, 56,172);
  color: white;
  padding: 0.7rem 0.6rem;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 1rem;
  font-size: 1.1rem;
}

.login-wrap input {
  outline: 0;
  border: 1px solid #c1c1c1;
  margin-top: 7px;
  border-radius: 4px;
  width: 100%;
  padding: 12px 8px;
  font-size: 18px;
}


.id-container {
  width: 100%;
  margin: 0;
}

.id-container a label{
  font-size: 15px;
  color: rgb(87, 87, 87);
  padding: 1rem;
  cursor: pointer;
}
.errorMessage{
  color: red;
  font-size: 0.9rem;
  margin-top: 0.3rem;
  text-align: left;
}

.loginBtn:disabled{
 background-color: #dadada;
 color: white;
 font-size: 1.1rem;
}

@media(max-width: 280px){
  
.login-container p {
  line-height: 0.7rem;
  font-size: 10px;
  color: rgb(116, 116, 116)
}
.id-container a label{
  font-size: 12px;
  color: rgb(87, 87, 87);
  padding: 1rem;
}
}