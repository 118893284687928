.mypageBoard-wrap{
  width: 95%;
  margin: 0 auto;
}

.mypageBoard {
  width: 100%;
  margin: 0 auto;
  line-height: 2.5rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #c1c1c1;
  text-align: center;
}
.mypageBoard td:first-child{
  width: 10%;
}
.mypageBoard td:nth-child(2){
  width: 10%;
}

.mypageBoard td:last-child{
  width: 15%;
}
.searchBox {
  vertical-align: bottom;
}
.searchBox select {
  margin-right: 1rem;
  height: 2.1rem;
}

.searchBox input {
  margin-right: 10px;
  border: 1px solid #c1c1c1;
  height: 2.2rem;
}
.searchBox button {
  border: 0;
  background-color: rgb(16, 56,172);
  padding: 0.37rem 1rem;
  border-radius: 2px;
  color: white;
  text-align: center;
  vertical-align: bottom;
  cursor: pointer;
}



.boardBottom{
  display:  flex;
 justify-content: space-between;
}
@media (max-width: 730px) {
  .mypageBoard td:nth-child(3){
    width: 60%;
  }
  
}

@media (max-width: 500px) {
  .mypageBoard td:first-child{
    width: 20%;
  }
  .mypageBoard td:nth-child(2){
    width: 55%;
  }
}