.qnaboard-wrap{
  width: 95%;
  margin: 0 auto;
}
.qnaboardwrite{
  text-align: right;
  margin-bottom: 0.8rem;
}
.boardwrite-btn{
  border: 0;
  background-color: rgb(16, 56,172);
  padding: 0.37rem 1rem;
  border-radius: 2px;
  color: white;
  text-align: center;
  vertical-align: bottom;
  cursor: pointer;
}
.qnaboard {
  width: 100%;
  margin: 0 auto;
  line-height: 2.5rem;
  margin-bottom: 1.2rem;
  border-bottom: 1px solid #c1c1c1;
  text-align: center;
}


.qnaboard strong {
  font-size: 1.1rem;
}

.qnaboard thead{ 
  color: black;
  height: 3rem;
  background-color:#e9e9e9;
  border-top: 1px solid black;
}
.qnaboard tbody tr{ 
  border-bottom: 1px solid #c1c1c1;
  color: black;
}

.qnaboard thead td:first-child{
  text-align: center;
}

.qnaboard td:first-child {
  /* width: 10%; */
  text-align: left;
  padding-left:13px;
}
.qnaboard td:nth-child(2) {
  width: 10%;
}

.qnaboard td:last-child {
  width: 10%;
}
@media (max-width:500px){
  .qnaboard td:nth-child(2) {
    width: 15%;
  }
  
  .qnaboard td:last-child {
    width: 15%;
  }
}

.searchBox {
  vertical-align: bottom;
}
.searchBox select {
  margin-right: 1rem;
  height: 2.1rem;
}

.searchBox input {
  margin-right: 10px;
  border: 1px solid #c1c1c1;
  height: 2.2rem;
}
.searchBox button {
  border: 0;
  background-color: rgb(16, 56,172);
  padding: 0.37rem 1rem;
  border-radius: 2px;
  color: white;
  text-align: center;
  vertical-align: bottom;
  cursor: pointer;
}


.pagination .page-item {
 color: black;

}

.pagination .page-item.active :hover {
  border-color:  grey;
  color: black;
}

.boardBottom{
  display:  flex;
  justify-content: space-between;
}
.qna-reply-button{
  cursor: pointer;
  margin-left: 5px;
  border: 1px solid grey;
  padding: 2px 4px;
  border-radius: 5px;
  margin-left: 10px;
  /* color: rgba(16, 56,172, 0.9); */
}
.qna-reply-button:hover{
   background-color: grey;
  color: white;
}

@media(max-width : 768px){
  .qnaboard tbody td:first-child{
    font-size: 13px;
  }
  .qnaboard tbody td:nth-child(2){
    width: 10%;
    font-size: 13px;
    text-align: center;
  }
  
  .qnaboard tbody td:last-child{
    width: 20%;
  }
}
@media(max-width : 500px){
  .qnaboard tbody td:first-child{
   width: 55%;
  }
  .qnaboard tbody td:nth-child(2){
    width: 20%;
    font-size: 13px;
    text-align: center;
  }
  
  .qnaboard tbody td:last-child{
    width: 20%;
  }
}