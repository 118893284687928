.mypage-wrap{
  width: 95%;
  margin: 0 auto;
}
.contentMypageSubtitle{
  width:100%;
  margin: 0 auto;
  margin-top: 4rem;
  text-align: left;
}

.contentMypageboard{
  font-size: 1.9rem;
  font-weight: bold;
  padding-bottom: 1rem;
  border-bottom: 2px solid grey;
  margin-bottom: 2rem;
}


.contentboard{
  font-size: 1.9rem;
  font-weight: bold;
  padding-bottom: 1rem;
  border-bottom: 2px solid grey;
  margin-bottom: 2rem;
}

.mypage-wrap h4 {
  font-size: 22px;
  margin-bottom: 13px;
}

.mypage-wrap td:first-child {
  width: 20%;
  font-weight: bold;
  font-size: 18px;
  text-align: left;
}
.mypage-wrap td:nth-child(2) {
  text-align: left;
  color: grey
}
.mypage-wrap table{
  line-height: 33px;
  font-size: 16px;
  margin-top: 15px;
  width: 94%;
  margin:0 auto;     
}

.mypage-wrap input {
  border: 1px solid #c1c1c1;
  border-radius: 3px;
  height: 28px;
  padding-left: 10px;
  width: 340px;
  margin-right: 10px;
}
.mypage-wrap h4{
  width: 95%;
  margin:0 auto;     
  text-align: left;
  font-size: 22px;
  margin-bottom: 13px;
}

.mypageBoard thead{ 
  color: black;
  height: 3rem;
  background-color:#e9e9e9;
  border-top: 1px solid black;
}
.btn-container {
  margin-top: 60px;
}
.btn-wrap button {
  width: 110px;
  height: 50px;
  font-size: 18px;
  margin-right:20px;
  border: 2px solid black;
  font-weight: bold;
  background-color: white;
}

.btn-container button:hover {
  cursor: pointer;
  background-color: black;
  color: white;
}

.mypage-icon{
  margin-left: 10px;
  font-size: 35px;
}
.mypage-icon p{
  font-size: 18px;
  margin-top:7px;
  font-weight: bold;
}
@media (max-width: 950px) {
  .mypage-wrap td:first-child {
    width: 30%;
  }
}

@media (max-width: 540px) {
  .mypage-wrap td:first-child {
    width: 50%;
  }
  .mypage-icon p {
    font-size: 15px;
  }
}

@media (max-width: 370px) {
  .mypage-wrap td:first-child {
    font-size: 12px;
    text-align: left;
  }
  .mypage-wrap td:nth-child(2) {
   font-size: 13px;
  }
  .mypage-icon p {
    font-size: 12px;
  }

}

@media (max-width: 280px) {
  .mypage-wrap td:first-child {
    font-size: 13px;
    text-align: left;
  }
  .mypage-wrap td:nth-child(2) {
   font-size: 12px;
  }
  .mypage-icon{
    margin-left: 2px;
    width: 80px;
  }
  .mypage-icon p{
    font-size: 12px;
    margin-top:7px;
    font-weight: bold;
  }
}