.aiContent{
    text-align: left;
    width: 95%;
    margin: 0 auto;
}


.aiHistory{
    line-height: 2rem;
}

@media(max-width: 500px){
    .aiContent .year{
    padding-right: 0px !important;
    }
}


@media(max-width: 360px){
    .aiContent .year{
    padding-right: 0px !important;
    font-size: 8px;
    }
}
