.fnqboard-wrap {
  width: 95%;
  margin: 0 auto;
}

.fnqboard {
  width: 100%;
  margin: 0 auto;
  line-height: 2.5rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #c1c1c1;
  text-align: center;
}

.searchBox {
  vertical-align: bottom;
}
.searchBox select {
  margin-right: 1rem;
  height: 2.1rem;
}

.searchBox input {
  margin-right: 10px;
  border: 1px solid #c1c1c1;
  height: 2.2rem;
}
.searchBox button {
  border: 0;
  background-color: rgb(16, 56, 172);
  padding: 0.37rem 1rem;
  border-radius: 2px;
  color: white;
  text-align: center;
  vertical-align: bottom;
  cursor: pointer;
}


.boardBottom {
  display: flex;
  justify-content: space-between;
}

.accordion .item {
  border: 1;
  border-radius: 0;
}

.accordion .item .accordion-header button {
  background-color: transparent;
  box-shadow: none;
  font-weight: 700;
}
.accordion .item .accordion-button.collapsed {
  color: black;
}

.accordion .item .accordion-button {
  color: rgb(16, 56, 172);
}
.accordion .item .accordion-header button::after {
  transition: all 0.6s ease;
}

.accordion .item .accordion-collapse {
  transition: all 0.6s ease;
}

@media (max-width: 1200px) {
  .accordionHead .accordion-button.collapsed::after {
    background-image: url("../image/up_black.svg");
    fill: red; 
  }
  .accordion .item .accordion-header button::after {
    background-image: url("../image/down_black.svg");
  }
}
