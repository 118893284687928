.reply-list {
  padding: 0 15px;
  font-size: 13.5px;
  font-weight: 600;
  border-bottom: 1px solid #e8e8e8;
}
.reply-container {
  padding: 0 15px;
  border-bottom: 1px solid #e8e8e8;
}
.reply-content {
  margin-top: -9px;
}

.reply-name {
  font-weight: bold;
  margin-right: 10px;
  font-size: 14px;
}

.reply-day {
  color: grey;
  font-size: 12px;
}

.reply-button span {
  margin-right: 6px;
}
.reply-button span:hover {
  cursor: pointer;
}

.editInput {
    width: 100%;
    padding-left: 5px;
    height: 25px;
    border: 1px solid  #e8e8e8;
}
.editInput:hover{
  border: 1px solid rgb(16, 56,172);
}