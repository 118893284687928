.boardInsert{
  width: 100%;
}

.boarderInsertTable{
  width: 95%;
  margin: 0 auto;
  text-align: left;
  line-height: 2.4rem;
  border-top: 1px solid black;
}
.boarderInsertTable tr{
  border-bottom: 1px solid #c1c1c1;
  height: 3.2rem; 
}

.boarderInsertTable td:first-child{
  background-color: #e9e9e9;
  width: 10%;
  font-weight: bold;
  padding-left: 1em;
  margin: 1rem;
}

.boarderInsertTable input{
  width: 98.5%;
  border: 1px solid #c1c1c1;
  margin: 0.5rem;
  padding-left: 10px;
}
.boarderInsertTable input::placeholder{
  padding-left: 0px;
}
.boarderInsertTable strong {
  font-size: 1.1rem;
}
.boarderInsertTable textarea{
  width: 99%;
  border: 1px solid #c1c1c1;
  margin: 0.5rem;
}
.QNABoardBtnGrp{ 
   width: 95%;
   margin: 0 auto;
   text-align: right; 
   margin-Top: 2rem;
}

.QNABoardBtnGrp button{
  border: 0;
  background-color: rgb(16, 56,172);
  height: 2.4rem;
  padding: 0.37rem 1rem;
  border-radius: 2px;
  color: white;
  text-align: center;
  vertical-align: bottom;
  cursor: pointer;
  margin-left: 0.6rem;
} 
input::placeholder {
  font-size: 1rem;
  padding-left: 0.5rem;
}
input[type="file"]{
   border:none; 
}

textarea::placeholder {
  font-size: 1rem;
  padding-left: 0.5rem;
}
.QnaBoardcontent{
  padding:0.5rem; 
  height: 400px;
}
.privateCheckbox{
  float: left;
  margin-top: 7px;  
}
.file-wrap{
  max-width:100%;
}
.file-name {
  padding-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-cancle{
  border: 1px solid gray;
  padding: 2px 5px;
  margin-left: 20px;
  margin-bottom: 10px;
}
@media (max-width: 650px){

  .boarderInsertTable td:first-child{
    background-color: #e9e9e9;
    width: 15%;
    font-weight: bold;
    padding-left: 1em;
    margin: 1rem;
    font-size:10px;
  }
  /* .file-wrap{
    width: 90%;
    margin:0 auto;
    background-color: red;
  } */
}

@media (max-width: 400px){

  .boarderInsertTable td:first-child{
    background-color: #e9e9e9;
    width: 18%;
    font-weight: bold;
    padding-left: 1em;
    margin: 1rem;
    font-size:8px;
  }
  /* .file-wrap{
    width: 80%;
    background-color: red;
  } */
}
  /* .ql-snow .ql-color-picker .ql-picker-label,
  .ql-snow .ql-icon-picker .ql-picker-label {
    padding: 0 !important;
  } */


/* 
.boardDetail tbody tr{
  border-bottom: 1px solid #c1c1c1;
}
.boardDetail tbody td{
  padding: 0 1.5rem;
}

.boardDetail {
  width: 100%;
  line-height: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #c1c1c1;
}

.boardDetail td:first-child {
  width: 15%;
}
.boardDetail td:nth-child(2) {
  width: 60%;
  text-align: left;
}
.listBox {
  border: 0;
  background-color: grey;
  padding: 3px 7px;
  border-radius: 2px;
  color: white;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
} */
