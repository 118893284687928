.noticetab-container{
    padding: 15px 20px;
}
.noticetab{
    text-align: center;
    justify-content: space-between;
    align-items: center;
    line-height: 38px;
}
.noticetab strong{
   font-size: 20px;
}
.noticetab span{
    color:  grey;
    font-size: 15px;
 }

 @media (max-width:900px) {
    .noticetab strong{
        font-size: 18px;
     }
 }

 @media (max-width:300px) {
    .noticetab strong{
        font-size: 15px;
     }
     .noticetab{
        line-height: 20px;
    }
    .noticetab span{
        color:  grey;
        font-size: 10px;
     }
    .noticetab-container{
        padding: 5px 20px;
    }
 }