.boardDetail{
  width: 100%;
}

.boardDetail-wrap{
  width: 95%;
  margin: 0 auto;text-align: left;
  line-height: 2.4rem;
}

.boardDetail strong {
  font-size: 18px;
}

.boardDetail thead{ 
  color: black;
  height: 80px;
  background-color:#e9e9e9;
  text-align: center;
  border-top: 1px solid black;
  border-bottom: 1px solid #c1c1c1;
}
.boardDetail tbody tr{
  border-bottom: 1px solid #c1c1c1;
}
.boardDetail tbody td{
  padding: 0 1.5rem;
}
.boardBtn-wrap{ 
  width:100%;
  margin: 0 auto;
  text-align: right; 
  margin-Top: 2rem;
}
/* .gallery-ql-editor{
  max-width: 100%;
  height: auto;
} */
.boardBtn-wrap button{
  border: 0;
  background-color: rgb(16, 56,172);
  height: 2.4rem;
  padding: 0 1rem;
  /* padding: 0.37rem 1rem; */
  border-radius: 2px;
  color: white;
  text-align: center;
  cursor: pointer;
  margin-left: 0.6rem;
} 