.profile-box {
  width: 95%;
  margin: 0 auto;
}
.contentMypageSubtitle {
  width: 100%;
  margin: 0 auto;
  margin-top: 4rem;
  text-align: left;
}
.profile-title {
  margin-left: 20px;
}
.contentMypageboard {
  font-size: 1.9rem;
  font-weight: bold;
  padding-bottom: 1rem;
  border-bottom: 2px solid grey;
  margin-bottom: 2rem;
}

.profile-box h4 {
  font-size: 22px;
  margin-bottom: 13px;
}

.profile-box td:first-child {
  width: 18%;
  font-weight: bold;
  font-size: 18px;
  text-align: left;
}
.profile-box td:nth-child(2) {
  font-weight: bold;
  text-align: left;
}
.profile-box table {
  line-height: 33px;
  font-size: 16px;
  margin-top: 15px;
  width: 94%;
  margin: 0 auto;
}
.profile-box span {
  color: red;
}

.profile-box p {
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.profile-box input {
  border: 1px solid #c1c1c1;
  border-radius: 3px;
  height: 28px;
  padding-left: 10px;
  width: 340px;
  margin-right: 10px;
}
.btn-container {
  margin-top: 60px;
}
.btn-wrap button {
  width: 110px;
  height: 50px;
  font-size: 18px;
  margin-right: 20px;
  font-weight: bold;
  border: none;
  background-color: rgb(45, 52, 62);
  color: white;
}

.btn-container button:hover {
  cursor: pointer;
  background-color: black;
  color: white;
}

.profile-box .pw-condition {
  color: grey;
  font-size: 13px;
}
.checkbox-group {
  display: flex;
  align-items: center;
}
.checkbox-group input[type="checkbox"] {
  width: 16px;
  margin-left: 10px;
}
@media (max-width: 1100px) {
  .profile-box td:first-child {
    width: 30%;
  }
}
@media (max-width: 700px) {
  .profile-box td:first-child {
    width: 35%;
  }
  .profile-title {
    margin-left: 10px;
  }
  .profile-box input {
    border: 1px solid #c1c1c1;
    border-radius: 3px;
    height: 28px;
    padding-left: 10px;
    width: 100%;
    margin-right: 10px;
  }
}

@media (max-width: 535px) {
  .profile-box td:first-child {
    width: 40%;
    font-size: 14px;
  }
  .profile-title {
    margin-left: 10px;
  }
  .profile-box input {
    border: 1px solid #c1c1c1;
    border-radius: 3px;
    height: 28px;
    padding-left: 10px;
    width: 100%;
    margin-right: 10px;
  }
  .checkbox-group label {
    font-size: 14px;
  }
}

/* @media (max-width: 368px) {
  .profile-title {
    margin-left: 5px;
  }
  .profile-title h4 {
    font-size: 18px;
  }
  .checkbox-group label {
    font-size: 12px;
  }
} */

@media (max-width: 368px) {
  .checkbox-group label {
    font-size: 5px;
  }
  .profile-title h4 {
    font-size: 17px;
  }
  .profile-box td:first-child {
    width: 32%;
    font-size: 13px;
  }
}
