.searchBox {
    vertical-align: bottom;
  }
  .searchBox select {
    margin-right: 1rem;
    height: 2.1rem;
  }
  
  .searchBox input {
    margin-right: 10px;
    border: 1px solid #c1c1c1;
    height: 2.2rem;
    padding-left: 5px;
  }
  .searchBox button {
    border: 0;
    background-color: rgb(16, 56,172);
    padding: 0.37rem 1rem;
    border-radius: 2px;
    color: white;
    text-align: center;
    vertical-align: bottom;
    cursor: pointer;
  }
    