.footer-container {
  background-color: black;
  color: rgb(181, 181, 181);
  padding: 3rem 0;
  font-size: 14px;
}

.footer-container .footer-wrap{
  text-align: center;
}

.footer-container .footer-ul{
   width: 55rem;
   margin: 0 auto;
}

.footer-container .footer-ul li{
  float: left;
}

.footer-container .footer-ul .footer-li{
 padding: 0 2rem;
 border-right: 1px solid rgb(181, 181, 181);
}
.footer-container .footer-ul .footer-lilast{
  padding: 0 2.5rem 0 2rem;
 }

.footer-container .footer-ul li a{
  color: rgb(181, 181, 181);
  text-decoration: none;
}

.footer-container p{
  font-size: 1.1rem;
  line-height: 1.5rem;
  margin-top: 0.9rem;
  margin-bottom: 0.8rem;
}

.ficon{
  color: rgb(181, 181, 181);
  padding: 0 0.4rem;
  margin-top: 0.23rem;
} 

.credit{
  font-size: 1rem;
}
/*900px footer*/
.footer-wrap-mobile{
  background-color: black;
  color: rgb(181, 181, 181);
  padding: 2rem 0;
  font-size: 1rem;
}

.footer-ul-moblie{
   width: 50rem;
   margin: 0 auto;
}

.footer-top{
  display: flex;
  width: 14rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  padding: 0;
}


.footer-middle li a{
 text-decoration: none;
 color: rgb(181, 181, 181);
 padding: 0 1rem;
 font-size: 1rem;
}

.footer-li-mobile{
  margin: 0 0.3rem;
  /* border-right: 1px solid rgb(181, 181, 181); */
  border-left: 1px solid rgb(181, 181, 181);
}

.footer-li a span{
  color: rgb(181, 181, 181);
}

.footer-lilast a span{
  color: rgb(181, 181, 181);
}

.footer-li-mobile a span{
  color: rgb(181, 181, 181);
}
.ficonImg{
  width: 25px;
  margin-top: 3px;
  margin-left: 3px;
}
.ficonImgT
{width: 25px;
  margin-top: 3px;
  margin-left: 7px;
}
@media (max-width: 280px){
  .footer-wrap-mobile p{
   font-size: 0.8rem;
  }
  .ficonImg{
    width: 18px;
    margin-left: 3px;
  }
  .ficonImgT
  {width: 18px;
    margin-left: 7px;
  }
}