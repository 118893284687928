.contentSubtitle{
    text-align: left;
}

.contentboard{
    font-size: 1.9rem;
    font-weight: bold;
    padding-bottom: 1rem;
    border-bottom: 2px solid grey;
    margin-bottom: 3rem;
}

.content{
    text-align: left;
    width: 95%;
    margin: 0 auto;
}
/* .bookwrap{

    padding-top: 3rem;
}*/
.bookImg{
    width: 65%;
    margin-top: 2rem;
} 

@media(max-width:990px){
    .bookImg{
        width: 90%;
        margin-top: 2rem;
    } 
}

@media(max-width:500px){
    .bookImg{
        width: 100%;
        margin-top: 2rem;
    } 
}