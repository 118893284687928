.boardDetail{
  width: 100%;
}

.boardDetail-wrap{
  width: 95%;
  margin: 0 auto;text-align: left;
  line-height: 2.4rem;
}

.boardDetail strong {
  font-size: 18px;
}
.boardDetail a{
  text-decoration: underline;
}
.boardDetail thead{ 
  color: black;
  height: 80px;
  background-color:#e9e9e9;
  text-align: center;
  border-top: 1px solid black;
  border-bottom: 1px solid #c1c1c1;
  padding: 10px 20px;
}
.boardDetail tbody tr{
  border-bottom: 1px solid #c1c1c1;
}
.boardDetail tbody td{
  font-size: 15px;
}
.boardBtn-wrap{ 
  width:100%;
  margin: 0 auto;
  text-align: right; 
  margin-Top: 2rem;
}

.boardBtn-wrap button{
  border: 0;
  background-color: rgb(16, 56,172);
  height: 2.4rem;
  padding: 0 1rem;
  /* padding: 0.37rem 1rem; */
  border-radius: 2px;
  color: white;
  text-align: center;
  cursor: pointer;
  margin-left: 0.6rem;
} 
.area-write{
  margin-top: 15px;
  padding: 5px 10px;
}
.ql-editor{
  min-height: 150px;
  padding: 10px 5px !important;
}
.ql-editor img {
  max-width: 100%;
  height: auto;
}

.area-write .box-textarea input {
  width:100%;
  height: 40px;
  padding: 2px 10px;
  border: 1px solid #c1c1c1;
  font-size: 13px;
  /* color: #999; */
}

.area-write .box-write {
  display: flex;
  justify-content: space-between;
}

.area-write .xe-label .secret{
  width: 12px;
  height: 12px;
  background-color: #fff;
  border: 1px solid #BEBFC5;
  border-radius: 2px;
}
.area-write .xe-label .xe-label-text{
  margin-left: 5px;
}

.area-write .box-textarea .btn_register {
  width: 100px;
  height: 40px;
  background:rgb(45, 52, 62);
  border: 1px solid rgb(46, 52, 62) ;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}
.reply-list{
  padding: 0 15px;
  font-size: 13.5px;
  font-weight: 600;
  border-bottom: 1px solid #e8e8e8;
}
.reply-container{
  padding: 0 15px;
  border-bottom: 1px solid #e8e8e8;
}
.reply-content{
  margin-top: -10px;
}

.reply-name{
  font-weight: bold;
  margin-right: 10px;
  font-size: 14px;
}

.reply-day{
  color: grey;
  font-size: 12px;

}

.reply-button span{
  margin-right: 6px;
}
.reply-button span:hover{
  cursor: pointer;
 }

 @media (max-width: 800px){
  .boardDetail .board-title{
    font-size: 15px;
    padding: 0 15px; 

   }
   /* .boardDetail thead{ 
    height: 80px !important;
  } */
 }

 @media (max-width: 500px){
  .boardDetail .board-title{
    font-size: 18px;
    padding: 0 15px; 
   }
   /* .boardDetail thead{ 
    height: 80px !important;
  } */
 }
