.emailPrivacy-wrap{
    text-align: left;
    border: 1px solid #c1c1c1;
    padding: 30px 40px 15px 40px; 
    border-radius: 10px;
    margin-top: 58px;
}
.emailPrivacy-wrap h4{
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
}
.emailPrivacy-wrap h6{
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}
.emailPrivacy-wrap p{
    font-size: 17px;
    line-height: 2rem;
 }