.qnaboardDetail{
  width: 100%;
}

.qnaboardDetail-wrap{
  width: 95%;
  margin: 0 auto;text-align: left;
  line-height: 2.4rem;
}

.qnaboardDetail strong {
  font-size: 18px;
}

.qnaboardDetail thead{ 
  color: black;
  height: 80px;
  background-color:#e9e9e9;
  text-align: center;
  border-top: 1px solid black;
  border-bottom: 1px solid #c1c1c1;
}
.qnaboardDetail tbody tr{
  border-bottom: 1px solid #c1c1c1;
}
.qnaboardDetail tbody td{
  padding: 0 1.5rem;
}

/* 
.qnaboardDetail {
  width: 100%;
  line-height: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #c1c1c1;
}

.qnaboardDetail td:first-child {
  width: 15%;
}
.qnaboardDetail td:nth-child(2) {
  width: 60%;
  text-align: left;
}
.listBox {
  border: 0;
  background-color: grey;
  padding: 3px 7px;
  border-radius: 2px;
  color: white;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
} */
