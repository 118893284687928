.rboard-wrap{
  width: 95%;
  margin: 0 auto;
}

.rboard {
  width: 100%;
  margin: 0 auto;
  line-height: 2.5rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #c1c1c1;
  text-align: center;
}
.rboard thead{ 
  color: black;
  height: 3rem;
  background-color:#e9e9e9;
  border-top: 1px solid black;
}
.rboard tbody tr{ 
  border-bottom: 1px solid #c1c1c1;
  color: black;
}
.rboardwrite{
  text-align: right;
  margin-bottom: 0.8rem;
}
.rboardwrite-btn{
  border: 0;
  background-color: rgb(16, 56,172);
  padding: 0.37rem 1rem;
  border-radius: 2px;
  color: white;
  text-align: center;
  vertical-align: bottom;
  cursor: pointer;
}


.rboard tbody td:first-child{
  width: 10%;
}
.rboard tbody td:nth-child(2){
  width: 10%;
  font-size: 11px;
}
.rboard tbody td:nth-child(3){
 text-align: left;
}
.rboard tbody td:nth-child(4){
  width: 10%;
}
.rboard tbody td:nth-child(5){
  width:5%;
}
.rboard tbody td:last-child{
  width: 10%;
}
.searchBox {
  vertical-align: bottom;
}
.searchBox select {
  margin-right: 1rem;
  height: 2.1rem;
}

.searchBox input {
  margin-right: 10px;
  border: 1px solid #c1c1c1;
  height: 2.2rem;
}
.searchBox button {
  border: 0;
  background-color: rgb(16, 56,172);
  padding: 0.37rem 1rem;
  border-radius: 2px;
  color: white;
  text-align: center;
  vertical-align: bottom;
  cursor: pointer;
}


.pagination .page-link {
 color: black;

}

.pagination .page-link .active {
  border-color:  grey;
  color: white;
}

.pagination .page-item.active a:hover {
  border-color:  grey;
  color: black;
}

.rboardBottom{
  display:  flex;
 justify-content: space-between;
}

@media(max-width : 768px){
  .rboard tbody td:first-child{
    width: 10%;
  }
  .rboard tbody td:nth-child(2){
    width: 15%;
    /* font-size: 10px; */
    text-align: center;
  }
  
  .rboard tbody td:nth-child(4){
    width: 15%;
  }
  .rboard tbody td:last-child{
    width: 20%;
  }
}
@media(max-width :346px){
.rboardBottom input{
  width: 100px;
}
}
@media(max-width :257px){
  .rboardBottom input{
    width: 50px;
  }
  }