@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+KR&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300&display=swap");
.loading404 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: "Noto Serif KR", serif;
}

.loading404 h1 {
  font-size: 190px;
}

.loading404 h4 {
  font-size: 24px;
}
.h4container {
  margin-bottom: 15px;
}
.loading404 a {
  font-size: 28px;
  color: rgb(16, 56,172);
  font-weight: bold;
  /* font-family: "Poppins", sans-serif; */
  text-decoration: none;
}

.loading404 img{
  width: 23rem;
}