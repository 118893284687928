.trend-container{
    width: 80%;
    margin: 0 auto;
}
.trend-p{
    font-size: 16.5px;
}
/* .trend-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
     Add any other styles you need for the content 
    } */
/* .trend-content-wrap{
    position: relative;
    height: 100%;
}
.trend-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */
@media (max-width:1300px){
    .trend-container p{
        font-size: 15px;
    }
}
@media (max-width:1100px){
    .trend-container p{
        font-size: 12px;
    }
}
@media (max-width:780px){
    .trend-container p{
        font-size: 15px;
    }
    .trend-container br{
     display: none;
    }
}
