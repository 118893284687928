
.gallery-wrap{
  width: 95%;
  margin: 0 auto;
}
.board strong {
  font-size: 1.1rem;
}

.gallery-thumbnail{
  width: 100%;
  height: 300px;
  object-fit: cover;
}



