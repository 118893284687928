.findIdTab-wrap{
  padding: 4rem 0;
  width: 30rem;
  margin: 0 auto;
}
/*
.findIdTab{
    border: 1px solid grey;
 }*/

.findIdTitle{
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
}

.tabtitle{
  background-color: white;
  flex:1;
  padding: 0.8rem;
  font-size: 1.3rem;
  color:black;
  border:1px solid grey;
}

.active-tab{
  background-color: rgb(16, 56,172);
  color:white;
  border: none;
}
.findIdContent{
    display: none;
    position: relative;
}

.active-content{
  display:block;
  margin: 2rem 3rem;
  /* transition: all 1s ease-in-out; */
}


.active-content input{
  outline: 0;
  border: 1px solid #c1c1c1;
  margin-top: 7px;
  border-radius: 4px;
  width: 80%;
  padding: 12px 8px;
  font-size: 1.1rem;
}

.active-content .confirmbtn {
  border: 0;
  background-color: rgb(16, 56,172);
  color: white;
  padding: 12px 8px;
  font-size: 1.1rem;
  border-radius: 3px;
  cursor: pointer;
  margin-top:7px;
}
.findIdfooter{
  text-align: left;
  line-height: 0.7rem;
  margin-left: 2rem;
}

.findIdfooter p{
  color:grey;
  margin-left: 1rem;
  margin-top: 0.8rem;
}